import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/css/main.css'

import VueRouter from 'vue-router'
import Login from './components/Login.vue'
import Wellcome from './components/Wellcome.vue'
import Reviewer from './components/Reviewer.vue'
import Admin from './components/Admin.vue'
import { BootstrapVue,BFormSelect } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios';
import vuetify from './plugins/vuetify'



Vue.use(VueRouter);
Vue.use(BootstrapVue);

const router = new VueRouter({
  mode: 'history',
  routes:[
    { path: '/login', name: 'login', component: Login},
    { path: '/wellcome', name: 'wellcome', component: Wellcome},
    { path: '/reviewer', name: 'reviewer', component: Reviewer},
    { path: '/admin', name: 'admin', component: Admin},
    {
      path: '*',
      redirect: '/login'
    }
  ]
});

Vue.config.productionTip = false

const vm=new Vue({
  el: '#app',
  render: h => h(App),
  vuetify,
  router
})
