<template>
  <v-container fluid align="center" class="min-height">

    <v-row class="d-flex justify-center">
      <v-col>
        <span>{{imgWaiting}} {{imgWaiting !== 1 ? "tickets": "ticket"}} por revisar</span>
        <span style="float: right;">v1.0.0</span>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import '@/assets/css/main.css'
import '@/assets/css/footer.css'

import  axios  from 'axios'

export default {
        name: 'footer-layout',
        created: function () {
          this.repeatGetTicketsWaiting();
        },
        data:() =>({
            imgWaiting: 0
        }),
        methods: {
          repeatGetTicketsWaiting:function () {
            this.getTicketsWaiting(); // primera llamada para mostrar tickets en espera
            setInterval(() => {
              this.getTicketsWaiting(); //despues de 30 segundos se repite
            }, 30000);
          },
          getTicketsWaiting:function () {
            axios.get("/api/tickets/waiting", {
              params: {
                  project: sessionStorage.getItem('selProject'),
              }
            })
            .then(response=>{
                if (response.data.count){
                  this.imgWaiting = response.data.count;
                } else {
                  this.imgWaiting = 0
                }
                
            })
          }
        }
}

</script>