<template>
    <v-container fluid align="center">

      <v-row class="d-flex justify-center" id="row_buscador">
        <v-col :cols="12">
            <v-select
                v-model="selectedType"
                :items="resultsTypes"
                :item-text="'tipoResult'"
                :item-value="'tipoResultValor'"
                label="Selecciona"
                return-object 
                single-line
                v-on:change="changeTipoResult">
            </v-select>
        </v-col>
        <v-col :cols="12">
            <p>{{selectedTag == null ? 'No hay etiqueta activa' : selectedTag.className}}</p>
        </v-col>
        <v-col :cols="12">
            <v-text-field
                v-model="buscador"
                label="Buscar etiqueta"
                clearable
                outlined
                @input="buscarEtiqueta(buscador)"
            ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center two-rows-height">
        <v-col :cols="12">
            <v-card class="min-height scroll">
                <v-card-title class="justify-center">
                    <p>Tags usados</p>
                </v-card-title>
                <v-card class="tableTagContainer">
                    <v-radio-group v-model="selectedTag" v-on:change="functionSelectedUsedTag()">
                        <table width="100%">
                            <thead>
                                <tr>
                                    <th>
                                        <v-icon>
                                        mdi-tag-search
                                        </v-icon>
                                    </th>
                                    <th>
                                        <v-btn icon @click="mostrarOcultarTodos">
                                            <v-icon>mdi-eye-off</v-icon>
                                        </v-btn>
                                    </th>
                                    <th>
                                        Color
                                    </th>
                                    <th>
                                        [{{ totalUsedTags }}]
                                    </th>
                                    <th class="textTag">
                                        Etiquetas
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(tag, index) in usedTagsVal_filter">
                                    <td>
                                        <v-radio
                                            :key="index"
                                            :value="tag"
                                            :color="tag.color"
                                        ></v-radio>
                                    </td>
                                    <td>
                                        <v-checkbox
                                            v-model="selectedCheckboxTag"
                                            :color="tag.color"
                                            :value="tag.id"
                                            hide-details
                                            v-on:change="changeSelectedCheckboxTag"
                                        ></v-checkbox>
                                    </td>
                                    <td>
                                        <v-chip
                                            class="ma-2"
                                            :color="tag.color"
                                            label
                                        >
                                            <v-icon> mdi-label</v-icon>   
                                        </v-chip>
                                    </td>
                                    <td>
                                        {{ tag.usedCount }}
                                    </td>
                                    <td class="textTag" @contextmenu="clickDerecho($event, tag.id)">
                                        <span v-if="tag.className.length < 17">
                                            {{tag.className}}
                                        </span>
                                        <v-tooltip bottom v-else>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">{{tag.className}}</span>
                                            </template>
                                            <span>{{tag.className}}</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </v-radio-group>
                </v-card>
            </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center two-rows-height" >
        <v-col :cols="12">
            <v-card class="min-height scroll">
                <v-card-title class="justify-center">
                    <p>Tags no usados</p>
                </v-card-title>
                <v-card class="tableTagContainer">
                    <v-radio-group v-model="selectedTag" v-on:change="functionSelectedNotUsedTag()">
                        <table class="usedTags">
                            <thead>
                                <tr>
                                    <th>
                                        <v-icon>
                                        mdi-tag-search
                                        </v-icon>
                                    </th>
                                    <th>
                                        Color
                                    </th>
                                    <th>
                                        Etiquetas
                                    </th>
                                </tr>
                             </thead>
                            <tbody>
                                <tr v-for="(tag, index) in notUsedTagsVal_filter">
                                    <td>
                                        <v-radio
                                            :key="index"
                                            :value="tag"
                                        ></v-radio>
                                    </td>
                                    <td>
                                        <v-chip
                                            class="ma-3"
                                            :color="tag.color"
                                            label
                                        >
                                            <v-icon> mdi-label</v-icon>   
                                        </v-chip>
                                    </td>
                                    <td class="textTag">
                                        <span v-if="tag.className.length < 26">
                                            {{tag.className}}
                                        </span>
                                        <v-tooltip bottom v-else>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">{{tag.className}}</span>
                                            </template>
                                            <span>{{tag.className}}</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </v-radio-group>
                </v-card>
            </v-card>
        </v-col>
      </v-row>

    </v-container>
</template>

<style scoped>
    .v-card__title, p, .v-card__text > .v-input--radio-group{
        padding-top: 0;
        padding-bottom: 3px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .tableTagContainer .v-input {
        text-align: center !important;
    }

    .tableTagContainer .textTag {
        text-align: left !important;
    }

    .tableTagContainer .v-input--selection-controls__input {
        margin-right: 0px !important;
    }

    .v-application--is-ltr .v-input--selection-controls__input {
        margin-right: 0px !important;
    }

    .tableTagContainer > div {
        padding-top: 0;
        margin-top: 0;
    }

    .tableTagContainer thead {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: white;
    }

    .v-input--selection-controls{
        margin: 0;
        padding: 0;
    }
</style>

<script>
import '@/assets/css/tagslist.css'
import Vue from 'vue'
import Message from '@/helpers/message.js'
import {Tag} from "@/model/Tag.js"

export default {
        name: 'TagsLists',
        props: {
            tagsNotUsed: {
                type: Number,
                default: null
            },
            resultsTypes: {
                type: Array,
                default() {  return [{tipoResult:'Elementos',tipoResultValor:'elements'},
                            {tipoResult:'Predicciones',tipoResultValor:'predictions'},
                            {tipoResult:'Extras',tipoResultValor:'extras'}]
                }
            }
        }, 
        created: function () {
        }, 
        data:() =>({
             usedTagsVal: [],
             notUsedTagsVal: [],
             usedTagsVal_filter: [],
             notUsedTagsVal_filter: [],
             selectedTag: null,
             selectedCheckboxTag: [],
             buscador: null,
             selectedType:{tipoResult:'Predicciones',tipoResultValor:'predictions'},
             resultsType:null,
             totalUsedTags:0,
             mostrarEtiquetas: true,
        }),
        mounted () {
            window.addEventListener("resize", this.setTagsDimensions);
            this.setTagsDimensions()
        },
        destroyed() {
            window.removeEventListener("resize", this.setTagsDimensions);
        },
        methods: {
            changeTagsUsed(value) {
                console.log(value)
                if(value === 'clonar'){
                    this.functionUpdateTagsUsed(Vue.prototype.$canvas.listUsedTags);
                } else if(value === 'intercambiar'){
                    if (this.selectedTag.tagUsed === true) {
                        this.functionUpdateTagsUsed(Vue.prototype.$canvas.listUsedTags);
                    } else {
                        this.functionUpdateTagsUsed(Vue.prototype.$canvas.listUsedTags);
                        this.functionUpdateTagsNotUsed(Vue.prototype.$canvas.listNotUsedTags);
                    }
                } else {
                    this.functionUpdateTagsUsed(Vue.prototype.$canvas.listUsedTags);
                    this.functionUpdateTagsNotUsed(Vue.prototype.$canvas.listNotUsedTags);
                }
                this.$emit('eventChangeOverlay', false)
            },
            setTagsDimensions(e) {
                var containerCanvas_height = document.getElementById('containerCanvas').clientHeight;
                var row_buscador_height = document.getElementById('row_buscador').clientHeight;

                var _height = ((containerCanvas_height - row_buscador_height)/2);

                var rowCard = document.getElementsByClassName("two-rows-height");
                for(var i=0;i<rowCard.length;i++){
                    rowCard[i].style.maxHeight = _height + 'px';
                    rowCard[i].style.height = _height + 'px';
                }
                var cardClass = document.getElementsByClassName("scroll");
                for(var i=0;i<cardClass.length;i++){
                    cardClass[i].style.maxHeight = (_height - 10) + 'px';
                    cardClass[i].style.height = (_height - 10) + 'px';
                }
            },
            functionUpdateTagsUsed (usedTag) {
                let totalUsedTag=0;
                if (usedTag !== "undefined" && usedTag !== null) {
                    this.usedTagsVal=[];
                    for (const tag in usedTag) {
                        this.usedTagsVal.push({
                            id:usedTag[tag].local_id,
                            className:usedTag[tag].name,
                            usedCount:usedTag[tag].contUsedTag,
                            visible:true,
                            styleColor: "background-color: rgba("+usedTag[tag].color.red+", "+usedTag[tag].color.green+", "+usedTag[tag].color.blue+",1)",
                            color: "rgba("+usedTag[tag].color.red+", "+usedTag[tag].color.green+", "+usedTag[tag].color.blue+",.5)",
                        });
                        if (usedTag[tag].contUsedTag) {
                            totalUsedTag = totalUsedTag + usedTag[tag].contUsedTag;
                        }
                        this.selectedCheckboxTag.push(usedTag[tag].local_id);
                    }
                    this.usedTagsVal_filter = this.usedTagsVal;
                    this.totalUsedTags=totalUsedTag;
                }
            },
            functionUpdateTagsNotUsed (notUsedTag) {
                if (notUsedTag !== "undefined" && notUsedTag !== null) {
                    this.notUsedTagsVal=[];
                    for (const tag in notUsedTag) {
                        this.notUsedTagsVal.push({
                            id:notUsedTag[tag].local_id,
                            className:notUsedTag[tag].name,
                            styleColor: "background-color: rgba("+notUsedTag[tag].color.red+", "+notUsedTag[tag].color.green+", "+notUsedTag[tag].color.blue+",1)",
                            color: "rgba("+notUsedTag[tag].color.red+", "+notUsedTag[tag].color.green+", "+notUsedTag[tag].color.blue+",.5)",
                        });
                    }
                    this.notUsedTagsVal_filter = this.notUsedTagsVal;
                }
            },
            buscarEtiqueta(val){
                let _usedTagsVal = this.usedTagsVal;
                let _notUsedTagsVal = this.notUsedTagsVal;

                if(val == null){
                    this.usedTagsVal_filter = _usedTagsVal;
                    this.notUsedTagsVal_filter = _notUsedTagsVal;
                } else {

                    var buscador_useTag = [];
                    var buscador_notUseTag = [];

                    for (const useTag in _usedTagsVal) {

                        if(_usedTagsVal[useTag]['id'] === val || _usedTagsVal[useTag]['className'].toLowerCase().includes(val.toLowerCase())){
                            buscador_useTag.push(_usedTagsVal[useTag]);
                        }

                    }

                    for (const useTag in _notUsedTagsVal) {

                        if(_notUsedTagsVal[useTag]['id'] === val || _notUsedTagsVal[useTag]['className'].toLowerCase().includes(val.toLowerCase())){
                            buscador_notUseTag.push(_notUsedTagsVal[useTag]);
                        }

                    }

                    this.usedTagsVal_filter = buscador_useTag;
                    this.notUsedTagsVal_filter = buscador_notUseTag;
                }
            },
            functionSearchSelectedTag (tagsArray, selectedTag) { //Esta función busca y coloca en el canvas el activeTag para poder crear y modificar el Tag
                console.log(selectedTag)
                let tagFinded=new Tag();
                let canvas=Vue.prototype.$canvas;
                let tagR=0;
                let tagG=0;
                let tagB=0;
                if (canvas!== "undefined" && canvas!==null) {
                    for (const indexTag in tagsArray) {
                        if (tagsArray[indexTag].local_id === selectedTag.id) {
                            tagFinded.id=tagsArray[indexTag].local_id;
                            tagFinded.className=tagsArray[indexTag].name;
                            tagR=tagsArray[indexTag].color.red;
                            tagG=tagsArray[indexTag].color.green;
                            tagB=tagsArray[indexTag].color.blue;
                            let arrayRGBColor=new Array();
                            arrayRGBColor.push(tagR);
                            arrayRGBColor.push(tagG);
                            arrayRGBColor.push(tagB);
                            tagFinded.arrayRGBColor=arrayRGBColor;
                            canvas.activeTag=tagFinded;
                        }
                    }
                }
            },
            changeTipoResult: function () {
                this.$emit('eventUpdateSelectedResult', this.selectedType)
            },
            mostrarOcultarTodos: function(){
                if (this.selectedCheckboxTag.length < this.usedTagsVal.length) {
                    this.mostrarEtiquetas = true
                } else {
                    this.mostrarEtiquetas = !this.mostrarEtiquetas;
                }

                if(this.mostrarEtiquetas){
                    this.usedTagsVal.forEach(element => {
                        this.selectedCheckboxTag.push(element.id)
                    });
                } else {
                    this.selectedCheckboxTag = [];
                }

                this.changeSelectedCheckboxTag();
            },
            changeSelectedCheckboxTag: function () {
                let canvas=Vue.prototype.$canvas;

                canvas._objects.forEach((elementCanvas, key) => {
                    if(key > 0) {
                        elementCanvas.visible = false;
                    }
                });

                canvas._objects.forEach(elementCanvas => {
                    this.selectedCheckboxTag.forEach(elementCheck => {
                        if (elementCanvas.id === elementCheck) {
                            elementCanvas.visible = true;
                        }
                    });
                });

                canvas.setFitZoom();
            },
            clickDerecho: function(e, id) {
                let canvas=Vue.prototype.$canvas;

                canvas._objects.forEach((elementCanvas, key) => {
                    elementCanvas.visible = true;
                    if(key > 0 && elementCanvas.id !== id) {
                        elementCanvas.visible = false;
                    }
                });

                canvas.setFitZoom();

                setTimeout(() => {
                    canvas._objects.forEach((elementCanvas, key) => {
                        elementCanvas.visible = true;
                    });
                    canvas.setFitZoom();
                }, "3000");

                e.preventDefault();
            },
            functionSelectedUsedTag(){
                this.selectedTag.tagUsed = true;
                this.functionSearchSelectedTag(Vue.prototype.$canvas.listUsedTags,this.selectedTag);
            },
            functionSelectedNotUsedTag(){
                this.selectedTag.tagUsed = false;
                this.functionSearchSelectedTag(Vue.prototype.$canvas.listNotUsedTags,this.selectedTag);
            }
        },
        watch : {
        }
}
</script>