<template>
    <v-responsive class="d-flex align-center text-center fill-height">

        <v-card class="mx-auto px-6 py-8" max-width="344">
            <h1 class="py-3">Sign In</h1>

            <v-form
            v-model="form"
            @submit.prevent="onSubmit"
            >
            <v-text-field
                v-model="username"
                :readonly="loading"
                :rules="[required]"
                class="mb-2"
                label="Email"
            ></v-text-field>


            <v-text-field
                v-model="password"
                :readonly="loading"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[required]"
                :type="show1 ? 'text' : 'password'"
                label="Password"
                counter
                @click:append="show1 = !show1"
            ></v-text-field>

            <br>

            <v-btn
                :disabled="!form"
                :loading="loading"
                block
                color="success"
                size="large"
                type="submit"
                variant="elevated"
            >
                Iniciar sesión
            </v-btn>
            </v-form>
        </v-card>

    </v-responsive>
</template>

<script>
    import Vue from "vue";
    import '@/assets/css/login.css'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { fas, faLock, faUser} from '@fortawesome/free-solid-svg-icons'
    import  axios  from 'axios'
    import {sha256} from 'js-sha256'
    import jwt  from "jwt-decode";
    import Router from 'vue-router';
    import Message from '@/helpers/message.js'
    import VueCookies from 'vue-cookies'

    Vue.use(Router);
    Vue.use(VueCookies, { 
  // options here
    })

    library.add(fas)
    library.add(faLock)
    library.add(faUser)
    

    export default {
        name: 'Login',
        created: function () {
            document.title=process.env.VUE_APP_NAME;
            localStorage.clear();
            sessionStorage.clear();
        },
        mounted: function () {
            if ($cookies.get('percepthor-test-jwt')!==null) {
                this.creaSession($cookies.get('percepthor-test-jwt'));
            }
        },
        data:() => ({
            username: "luisger06",
            alias: "login",
            password: "hola12",
            criptopass: "",
            res: "",
            
            form: false,
            loading: false,
            show1: false,
            show2: true,
        }),
        methods: {
            onSubmit () {
                if (!this.form) return
                this.loading = true
                this.login()
            },
            required (v) {
                return !!v || 'Campo requerido'
            },
            getDataLogin () {
                let self =this;
                axios.defaults.baseURL="https://test.proxy.percepthor.com";
                axios.defaults.headers.get["Content-Type"]='application/x-www-form-urlencoded';
                axios.defaults.headers.get["Authorization"]=sessionStorage.getItem("token");
                axios.defaults.headers.get["X-Requested-With"]='XmlHttpRequest';
                axios.defaults.withCredentials=false;
                axios.get("/api/users/:id/info")
                .then (resp =>{
                    sessionStorage.setItem('realname',resp.data.name)
                    sessionStorage.setItem('idrole',resp.data.role.$oid)
                    if (resp.data.organization===null) {
                        sessionStorage.setItem('idorganization',null)
                    }
                })
                .catch(error=> {
                    //Message.SmallNoty('error',error.message)
                })

            },
            creaSession (token) {
                let self =this;
                console.log(token);
                sessionStorage.setItem(
                        "token",token
                        );
                        const user = jwt(token);
                        const userrole = user.role;
                        const userid=user.id;
                        const organization=user.organization;
                        const timestamp=Date.now();
                        const username=user.username;
                        sessionStorage.setItem(
                            "username", username
                        );
                        sessionStorage.setItem(
                            "role", userrole
                        );
                        sessionStorage.setItem(
                            "userid", userid
                        );
                        sessionStorage.setItem(
                            "idorganization", organization
                        );
                        sessionStorage.setItem(
                            "isLoggedIn", true
                        );
                        sessionStorage.setItem(
                            "dateLogin", timestamp
                        );
                        Message.SmallNoty("success","Loggin successful")
                        this.getDataLogin();
                        self.$router.push('Wellcome')
            },
            login () {
                let self =this;
                this.criptopass=sha256(this.password);
                const postdata = {
                    alias: this.alias,
                    username: this.username,
                    password: this.criptopass
                }
                const headers = {
                    "Content-Type": "application/json;charset=UTF-8",
                    Accept: "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                }
                
                axios.post("https://test.proxy.percepthor.com/api/users/login",postdata)
                .then(response=>{
                    this.loading = false
                    sessionStorage.setItem(
                    "tokenSub",
                        response.data.token.substring(
                            response.data.token.indexOf(" ") + 1,
                            response.data.token.lenght
                        )
                    );
                    if (response.data.password==="Password is incorrect!") {
                        response.data.password=""
                    } else {
                        this.creaSession(response.data.token);
                    }
                })
                .catch(
                    error=> {
                        this.loading = false
                        console.error("mensaje",error)
                        if (error.request.response) {
                            Message.SmallNoty('warning',error.request.responseText)
                        } else {
                            Message.SmallNoty('error',error.message)
                        }
                    }
                )
            },
            
        }
    }

</script>