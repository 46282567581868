import {fabric} from 'fabric'

export var PercepthorImage = fabric.util.createClass(fabric.Image,{
    type: 'percepthorImage',
    initialize: function (options) { //Constructor
        options || (options = { });
        this.callSuper('initialize', options);
        this.set({id : 'img' });
        this.set({image : options.image || null });
        this.set('name', options.name || '');  //Atributos nuevos
        this.set('extension', options.extension || '' );
        this.set('width', options.width || null );  
        this.set('height', options.height || null );
        this.set({listResults : options.listResults || [] });
        this.set({left : 0 }); //Ajustamos arriba a la izq la imagen de manera permanente y deshabiliotamos los controles
        this.set({top : 0 });
        this.set({selectable : false }); //Deshabilitamos los controles
        this.set({hasBorders : false });
        this.set({hasControls : false });
        this.set({hasRotatingPoint : false });
    },
    toObject: function() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
            id: this.get('id'),
            image: this.get('image'),
            name: this.get('name'),
            extension: this.get('extension'),
            width: this.get('width'),
            height: this.get('height'),
            listResults: this.get('listResults'),
        });
    },
    _render: function(ctx) { //This run when execute canvas.requestRenderAll() or its objects is modificaded
        this.callSuper('_render', ctx);
    },
});

//This function is used when cloned a PercepthorImage Object
//https://github.com/fabricjs/fabric.js/issues/2668
fabric.PercepthorImage = PercepthorImage

fabric.PercepthorImage.fromObject = function(options) {
	return new fabric.PercepthorImage(options);
}
