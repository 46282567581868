<template>
    <v-container fluid class="card fill-height">
        <v-col :cols="12">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large v-bind="attrs" v-on="on" :class="buttonActive == 1 ? 'buttonSelected' : ''" @click="selectButton(1); selectModeCanvas()">
                        <v-icon color="black">
                            mdi-cursor-pointer
                        </v-icon>
                    </v-btn>
                </template>
                <span>MODO SELECCIÓN</span>
            </v-tooltip>
        </v-col>
<!--
        <v-col :cols="12">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large v-bind="attrs" v-on="on" :class="buttonActive == 2 ? 'buttonSelected' : ''" @click="selectButton(2);">
                        <v-icon color="black">
                            mdi-selection-drag
                        </v-icon>
                    </v-btn>
                </template>
                <span>SELECCIONAR CON RECTANGULO</span>
            </v-tooltip>
        </v-col>

        <v-col :cols="12">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large v-bind="attrs" v-on="on" :class="buttonActive == 3 ? 'buttonSelected' : ''"  @click="selectButton(3);">
                        <v-icon color="black">
                            mdi-square-edit-outline
                        </v-icon>
                    </v-btn>
                </template>
                <span>MODO EDICIÓN</span>
            </v-tooltip>
        </v-col>

        <v-col :cols="12">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large v-bind="attrs" v-on="on" :class="buttonActive == 4 ? 'buttonSelected' : ''"  @click="selectButton(4);">
                        <v-icon color="black">
                            mdi-vector-square-edit
                        </v-icon>
                    </v-btn>
                </template>
                <span>CAMBIO DE TAMAÑO</span>
            </v-tooltip>
        </v-col>
-->        
        <v-col :cols="12">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large v-bind="attrs" v-on="on" @click="resetSize()">
                        <v-icon color="black">
                            mdi-image-size-select-large
                        </v-icon>
                    </v-btn>
                </template>
                <span>AJUSTAR IMAGEN AL PANEL</span>
            </v-tooltip>
        </v-col>
        
        <v-col :cols="12">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large v-bind="attrs" v-on="on" :class="buttonActive == 6 ? 'buttonSelected' : ''" @click="selectButton(6); createMode()">
                        <v-icon color="black">
                            mdi-vector-square-plus
                        </v-icon>
                    </v-btn>
                </template>
                <span>MODO AGREGAR ARTÍCULOS</span>
            </v-tooltip>
        </v-col>
        <v-col :cols="12">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large v-bind="attrs" v-on="on" :class="buttonActive == 7 ? 'buttonSelected' : ''" @click="selectButton(7); inspectModeCanvas()">
                        <img src="@/assets/svg/vector-square-search.svg">
                    </v-btn>
                </template>
                <span>MODO DE INSPECCIÓN</span>
            </v-tooltip>
        </v-col>
        <v-divider></v-divider>
        <v-col :cols="12">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large v-bind="attrs" v-on="on"  @click="changeTags()">
                        <img src="@/assets/svg/vector-square-change.svg">
                    </v-btn>
                </template>
                <span>INTERCAMBIAR TAGS</span>
            </v-tooltip>
        </v-col>
        
        <v-col :cols="12">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large v-bind="attrs" v-on="on"  @click="cloneArticles()">
                        <v-icon color="black">
                            mdi-content-duplicate
                        </v-icon>
                    </v-btn>
                </template>
                <span>CLONAR ETIQUETA</span>
            </v-tooltip>
        </v-col>
        
        <v-col :cols="12">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large v-bind="attrs" v-on="on"  @click="deleteArticles()">
                        <img src="@/assets/svg/vector-square-delete.svg">
                    </v-btn>
                </template>
                <span>ELIMINAR ETIQUETA</span>
            </v-tooltip>
        </v-col>

        <v-col :cols="12">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large v-bind="attrs" v-on="on"  @click="lockArticles()">
                        <v-icon color="black">
                            mdi-lock
                        </v-icon>
                    </v-btn>
                </template>
                <span>BLOQUEAR</span>
            </v-tooltip>
        </v-col>

        <v-col :cols="12">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large v-bind="attrs" v-on="on" @click="unlockAticles()">
                        <v-icon color="black">
                            mdi-lock-open
                        </v-icon>
                    </v-btn>
                </template>
                <span>DESBLOQUEAR</span>
            </v-tooltip>
        </v-col>
        
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-col :cols="12" style="margin-top: auto!important; ">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large v-bind="attrs" v-on="on" @click="viewDescription()" style="padding-bottom: 10px;">
                        <v-icon color="black">
                            mdi-information-outline
                        </v-icon>
                    </v-btn>
                </template>
                <span>Información</span>
            </v-tooltip>
        </v-col>
        <v-dialog
            v-model="dialog"
            width="500"
            >
            <v-card>
                <v-card-title class="text-h5 grey">
                Información
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col :cols="4" style="text-align: right;">
                            ID ticket:
                        </v-col>
                        <v-col :cols="8" style="text-align: left;">
                            {{ data_id_ticket }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col :cols="4" style="text-align: right;">
                            ID payload:
                        </v-col>
                        <v-col :cols="8" style="text-align: left;">
                            {{ data_id_payload }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col :cols="4" style="text-align: right;">
                            ID imagen:
                        </v-col>
                        <v-col :cols="8" style="text-align: left;">
                            {{ data_id_imagen }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col :cols="4" style="text-align: right;">
                            Nombre:
                        </v-col>
                        <v-col :cols="8" style="text-align: left;">
                            {{ data_name }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col :cols="4" style="text-align: right;">
                            Descripción:
                        </v-col>
                        <v-col :cols="8" style="text-align: left;">
                            {{ data_description }}
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                >
                    Aceptar
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style scoped>
    .buttonSelected{
        background-color: #757575;
    }
    .buttonSelected i, .buttonSelected img {
        -webkit-filter: invert(100%);
        filter: invert(100%);
    }
</style>

<script>

import '@/assets/css/tools.css'
import { fabric }  from 'fabric'
import { PercepthorArticle } from '@/model/PercepthorArticle'
import { lock_unlock_ActiveObject } from '@/helpers/fabricJsCustom.js'
import { deleteActiveObject } from "@/helpers/fabricJsCustom.js"
import { cloneActiveObject } from "@/helpers/fabricJsCustom.js"
import { switchActiveTag } from '@/helpers/fabricJsCustom.js'
import { Message } from "@/helpers/message.js"
import Vue from 'vue'

export default {
        name: 'tools_panel',
        props: {
            showDialog: { 
                type: Boolean, 
                default: false,
            },
        },
        watch: { 
            showDialog: function(newVal, oldVal) {
                if(newVal){
                    this.viewDescription();
                    this.$emit('eventShowModalInfo', false);
                }
            }
        },
        created: function () {
        },
        data:() =>({
            buttonActive: 1,
            dialog: false,
            data_name: null,
            data_description: null,
            data_id_ticket: null,
            data_id_payload: null,
            data_id_imagen: null
        }),
        methods: {
            updateTags: function (tipo) {
                var _canvas = Vue.prototype.$canvas._objects;
                
                var _listUsedTags = Vue.prototype.$canvas.listUsedTags;
                
                _canvas = JSON.parse(JSON.stringify(_canvas));
                _canvas.shift();

                _listUsedTags.forEach(element => {
                    var _count = 0;
                    _canvas.forEach(element2 => {
                        if(element.local_id == element2.tag.id){
                            _count++;
                        }
                    });
                    element.contUsedTag = _count;
                });

                this.$emit('eventUpdateTags', tipo)
            },
            selectButton: function (val) {
                this.buttonActive = val
            },
            createMode: async function () {
                const canvas = Vue.prototype.$canvas;
                canvas.hoverCursor = 'crosshair';
                canvas.cursorMode="draw";
            },
            resetSize:async function () {
                const canvas = Vue.prototype.$canvas;
                canvas.setWidth(0);
                canvas.setHeight(0);
                canvas.setWidth(canvas.wrapperEl.parentElement.parentElement.clientWidth-10);
                canvas.setHeight(canvas.wrapperEl.parentElement.parentElement.clientHeight-10);
                canvas.setFitZoom();
            },
            deleteArticles:async function () {
                const canvas = Vue.prototype.$canvas;
                deleteActiveObject(canvas);
            },
            lockArticles: function () {
                const canvas = Vue.prototype.$canvas;
                lock_unlock_ActiveObject(canvas,true);
            },
            unlockAticles: function () {
                const canvas = Vue.prototype.$canvas;
                lock_unlock_ActiveObject(canvas,false);
            },
            viewDescription: function () {
                if (sessionStorage.getItem('actual_data_description') !== null) {
                    this.data_id_ticket = sessionStorage.getItem("actual_oid_Ticket");
                    this.data_id_payload = sessionStorage.getItem('actual_oid_payload');
                    this.data_id_imagen = sessionStorage.getItem('actual_oid_Img');
                    this.data_name = JSON.parse(sessionStorage.getItem('actual_data_description')).name;
                    this.data_description = JSON.parse(sessionStorage.getItem('actual_data_description')).description;
                }
                this.dialog = true;
            },
            changeTags: function () {
                let canvas=Vue.prototype.$canvas;
                switchActiveTag(canvas, canvas.activeTag);
                this.updateTags('intercambiar');
            },
            cloneArticles: function () {
                let canvas=Vue.prototype.$canvas;
                cloneActiveObject(canvas);
                this.updateTags('clonar');
            },
            selectModeCanvas: function () {
                let canvas=Vue.prototype.$canvas;
                canvas.hoverCursor = 'cell';
                let cadAux="selección";
                canvas.cursorMode="select";
                Message.SmallNoty('success', `Modo [${cadAux}] en el panel activado.`)
            },
            inspectModeCanvas: function () {
                //Set sleccion mode by default
                let canvas=Vue.prototype.$canvas;
                let modoActual=canvas.cursorMode;
                console.log(modoActual);
                let mode="select";
                if (modoActual === "select") {
                    mode="inspect";
                } else if (modoActual === "inspect") {
                    mode="select";
                }
                let cadAux = 'selección';
                canvas.hoverCursor = 'default';
                canvas.cursorMode = mode;

                if (mode === 'select') {
                    cadAux = 'selección';
                    canvas.hoverCursor = 'cell';
                    this.buttonActive=1;
                } 
                if (mode === 'inspect') {
                    cadAux = 'inspeccion';
                    canvas.hoverCursor = 'context-menu';
                    this.buttonActive=7;
                }
                Message.SmallNoty('success', `Modo [${cadAux}] en el panel activado.`)
            }
        }
}
</script>